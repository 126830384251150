export const convertToString = (value: string | number) => {
  return typeof value === 'number' ? value.toString() : value;
};

export const formatCurrency = (medianWage: string | null | undefined): string => {
  let wage: number | undefined;

  if (typeof medianWage === 'string') {
    wage = parseFloat(medianWage);
  }

  if (wage !== undefined && !isNaN(wage)) {
    let formattedWage = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(wage);

    // Remove trailing .00 if present
    if (formattedWage.endsWith('.00')) {
      formattedWage = formattedWage.replace(/\.00$/, '');
    }

    return formattedWage;
  }

  return 'N/A';
};

export const toWagePerYear = (val: string | null | undefined): string => {
  if (!val) {
    return 'N/A';
  }
  const formatted = formatCurrency(val);
  return formatted === 'N/A' ? 'N/A' : `${formatted} Avg/yr`;
};

export const generateValidUrl = (urlString: string) => {
  let validUrl = urlString;
  // Check if the string already contains "http" or "https", to avoid broken links
  if (!validUrl.startsWith('http://') && !validUrl.startsWith('https://')) {
    // If not, prepend "https://" to the string

    validUrl = `https://${validUrl}`;
  }

  // Create a URL object for validation and correction
  try {
    const url = new URL(validUrl);
    return url.href; // Return the validated URL
  } catch (e) {
    // Handle invalid URLs or potential errors
    return null;
  }
};
